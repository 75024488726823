import { useNavigate } from "react-router-dom";
import React, {useContext } from "react";
import "./Category.scss";
import { Context } from "../../../utils/context";

const Category = () => {
    const navigate = useNavigate();
    const {categories, setCategories } = useContext(Context);

    // console.log("categories",categories)
    return (
        <div className="shop-by-category">
            <div className="categories">
                {categories?.data?.map((item) => (
                    <div
                        key={item.id}
                        className="category"
                        onClick={() => navigate(`/category/${item.id}`)}
                    > 
                    
                        <img
                            src={
                                process.env.REACT_APP_STRIPE_APP_DEV_URL +
                                item.attributes.img.data.attributes.url
                            } alt = 'image'
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Category;
